import { combineReducers } from "redux";

import { api, api2, api3, api4 } from "api";
import LoginSlice from "slices/loginSlice";
import FiltersSlice from "slices/filtersSlice";
import MemberFilterSlice from "slices/memberFilterSlice";
import MemberSearchSlice from "slices/memberSearchSlice";
import SavedFilterSlice from "slices/savedFilterSlice";
import MemberSlice from "slices/memberSlice";
import MemberActiveSlice from "slices/memberActiveSlice";
import PreSalesFilterSlice from "slices/preSalesFilterSlice";
import PreSalesSearchSlice from "slices/preSalesSearchSlice";
import AssignTrainingFilterSlice from "slices/assignTrainingFilterSlice";
import InterviewTrackerFilterSlice from "slices/interviewTrackerFilterSlice";
import CaseStudyFilterSlice from "slices/caseStudyFilterSlice";
import TestimonialFilterSlice from "slices/testimonialSlice";
import TabsSlice from "slices/tabsSlice";
import RecommendationsTabSlice from "slices/recommendationsTabSlice";
import ReferSlice from "slices/referSlice";
import MockInterviewSlice from "slices/mockInterviewSlice";
import ReportCardFilterSlice from "slices/reportCardFilterSlice";
import PSCardSlice from "slices/preSalesCardSlice";
import LicenseToolsFilterSlice from "slices/licenseToolsFilterSlice";
import LoadingSlice from "slices/loadingSlice";
const rootReducer = combineReducers({
  LoginSlice,
  FiltersSlice,
  CaseStudyFilterSlice,
  TestimonialFilterSlice,
  MemberFilterSlice,
  MemberSearchSlice,
  MemberActiveSlice,
  SavedFilterSlice,
  MemberSlice,
  PreSalesFilterSlice,
  PreSalesSearchSlice,
  InterviewTrackerFilterSlice,
  AssignTrainingFilterSlice,
  TabsSlice,
  ReferSlice,
  MockInterviewSlice,
  ReportCardFilterSlice,
  PSCardSlice,
  LicenseToolsFilterSlice,
  RecommendationsTabSlice,
  LoadingSlice,
  [api.reducerPath]: api.reducer,
  [api2.reducerPath]: api2.reducer,
  [api3.reducerPath]: api3.reducer,
  [api4.reducerPath]: api4.reducer,
});

export default rootReducer;
