import T from "T";

export const VIEW_PERMISSION = "view";
export const ADD_PERMISSION = "add";
export const EDIT_PERMISSION = "edit";
export const DELETE_PERMISSION = "delete";
export const PM_FILTER_PERMISSION = "pm_filter";

const {
  DASHBOARD,
  REPORTS,
  MEMBERS,
  NON_COMPLIANCE,
  ROLES,
  MASTER_SETTINGS,
  ACCOUNTING,
  PRE_SALES,
  TRAINING,
  CASE_STUDY,
  TESTIMONIAL,
  TECH_ASSIGNMENT,
  INSIDE_SALES,
  IS_TOOLS,
  PERMISSIONS,
  VIEW,
  ADD,
  EDIT,
  DELETE,
  PM_FILTER,
  MOCK_ASSESSMENT,
} = T;

export const ALL_PERMISSION_KEYS = {
  // TRACKER_VIEW_KEY: "tracker_view",
  DASHBOARD_VIEW_KEY: "dashboard_view",
  CASE_STUDY_VIEW_KEY: "casestudy_view",
  CASE_STUDY_ADD_KEY: "casestudy_add",
  CASE_STUDY_DELETE_KEY: "casestudy_delete",
  CASE_STUDY_EDIT_KEY: "casestudy_edit",
  TESTIMONIAL_VIEW_KEY: "Testimonial_view",
  TESTIMONIAL_ADD_KEY: "Testimonial_add",
  TESTIMONIAL_DELETE_KEY: "Testimonial_delete",
  TESTIMONIAL_EDIT_KEY: "Testimonial_edit",
  REPORT_VIEW_KEY: "report_view",
  REPORT_ADD_KEY: "report_add",
  REPORT_EDIT_KEY: "report_edit",
  REPORT_DELETE_KEY: "report_delete",
  USER_VIEW_KEY: "user_view",
  USER_ADD_KEY: "user_add",
  USER_EDIT_KEY: "user_edit",
  USER_DELETE_KEY: "user_delete",
  ROLE_VIEW_KEY: "role_view",
  ROLE_ADD_KEY: "role_add",
  ROLE_EDIT_KEY: "role_edit",
  ROLE_DELETE_KEY: "role_delete",
  NC_VIEW_KEY: "nc_view",
  NC_ADD_KEY: "nc_add",
  NC_EDIT_KEY: "nc_edit",
  NC_DELETE_KEY: "nc_delete",
  NC_PM_FILTER: "nc_pm_filter",
  MASTER_SETTINGS_VIEW_KEY: "master_view",
  MASTER_SETTINGS_ADD_KEY: "master_add",
  MASTER_SETTINGS_EDIT_KEY: "master_edit",
  MASTER_SETTINGS_DELETE_KEY: "master_delete",
  ACCOUNTING_VIEW_KEY: "accounting_view",
  ACCOUNTING_EDIT_KEY: "accounting_edit",
  ACCOUNTING_ADD_KEY: "accounting_add",
  ACCOUNTING_DELETE_KEY: "accounting_delete",
  PRE_SALES_VIEW_KEY: "pre_sales_view",
  PRE_SALES_EDIT_KEY: "pre_sales_edit",
  PRE_SALES_ADD_KEY: "pre_sales_add",
  PRE_SALES_DELETE_KEY: "pre_sales_delete",
  TRAINING_VIEW_KEY: "training_view",
  TRAINING_ADD_KEY: "training_add",
  TRAINING_EDIT_KEY: "training_edit",
  TRAINING_DELETE_KEY: "training_delete",
  TRAINING_REVIEW_KEY: " training_review",
  TRAINING_KRA_KEY: "training_kra",
  TRAINEE_VIEW_KEY: "trainee_view",
  INSIDE_SALES_VIEW_KEY: "inside_sales_view",
  INSIDE_SALES_ADD_KEY: "inside_sales_add",
  INSIDE_SALES_EDIT_KEY: "inside_sales_edit",
  INSIDE_SALES_DELETE_KEY: "inside_sales_delete",
  AI_ASSESSMENT_ADD_KEY: "aiAssessment_add",
  AI_ASSESSMENT_EDIT_KEY: "aiAssessment_edit",
  AI_ASSESSMENT_VIEW_KEY: "aiAssessment_list",
  AI_ASSESSMENT_DELETE_KEY: "aiAssessment_delete",
  MOCK_ASSESSMENT_VIEW_KEY: "mockAssessment_view",
  IS_TOOLS_VIEW_KEY: "is_tools_view",
  IS_TOOLS_ADD_KEY: "is_tools_add",
  IS_TOOLS_EDIT_KEY: "is_tools_edit",
  IS_TOOLS_DELETE_KEY: "is_tools_delete",
  REFERRAL_VIEW_KEY: "referral_view",
  REFERRAL_TA_VP_KEY: "ta_vp_view",
  REFERRAL_TA_KEY: "ta_view",
  PROFILE_VIEW_KEY: "profile_view",
  TECHNICAL_INTERVIEW_VIEW_KEY: "ti_view",
  TECHNICAL_INTERVIEW_EDIT_KEY: "ti_edit",
  TECHNICAL_INTERVIEW_ADD_KEY: "ti_add",
  TECHNICAL_INTERVIEW_DELETE_KEY: "ti_delete",
};

export const ROLES_MAPPING = {
  [DASHBOARD]: "dashboard",
  [REPORTS]: "report",
  [MEMBERS]: "user",
  [NON_COMPLIANCE]: "nc",
  [ROLES]: "role",
  [MASTER_SETTINGS]: "master",
  [ACCOUNTING]: "accounting",
  [PRE_SALES]: "pre_sales",
  [TRAINING]: "training",
  [INSIDE_SALES]: "inside_sales",
  [CASE_STUDY]: "casestudy",
  [TESTIMONIAL]: "testimonial",
  [TECH_ASSIGNMENT]: "tech_assignment",
  [MOCK_ASSESSMENT]: "mock_assessment",
  [IS_TOOLS]: "is-tools",
};

export const ROLES_PERMISSIONS = {
  [DASHBOARD]: [VIEW_PERMISSION],
  [REPORTS]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [MEMBERS]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [NON_COMPLIANCE]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION, PM_FILTER_PERMISSION],
  [ROLES]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [MASTER_SETTINGS]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [ACCOUNTING]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [PRE_SALES]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [TRAINING]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [INSIDE_SALES]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  [TECH_ASSIGNMENT]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION],
  [MOCK_ASSESSMENT]: [VIEW_PERMISSION],
  [IS_TOOLS]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  // [CASE_STUDY]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
  // [TESTIMONIAL]: [VIEW_PERMISSION, ADD_PERMISSION, EDIT_PERMISSION, DELETE_PERMISSION],
};

export const TABLE_HEADER_VALUES = [PERMISSIONS, VIEW, ADD, EDIT, DELETE, PM_FILTER];
