import { Children, useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import { Grid, MenuItem, Typography, Rating, Checkbox, FormControl, FormGroup, FormControlLabel } from "@mui/material";
import SelectCheck from "@mui/icons-material/Check";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import MISTextField from "components/common/MISTextField";
import MISDatePicker from "components/common/MISDatePicker";
import MISAutocomplete from "components/common/MISAutocomplete";
import T from "T";

import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";

import WithInputLabel from "components/common/WithInputLabel";
import {
  JIRA_FREQUENCY,
  LEVEL_LIST,
  PORTFOLIO_LINK,
  PROJECT_MODE,
  REGION_LIST,
  YES_NO_VALUES,
  YES_NO_VALUES_ARRAY,
} from "./memberModel";
import JiraNameConfirm from "./JiraNameConfim";
import { isUrl } from "utils/validations";

const PrevComp = ({
  index,
  previousCompanies,
  onHandleMultiChange = noop,
  resetName = noop,
  companyList,
  setDuplicateCompany = noop,
}) => {
  const checkCompanyExists = (name) => {
    return companyList.some((company) => company.companyName === (name ? name.trim() : name));
  };
  const companyExists = checkCompanyExists(previousCompanies[index].name);

  useEffect(() => {
    if (companyExists) {
      setDuplicateCompany(true);
    } else {
      setDuplicateCompany(false);
    }
  }, [companyExists]);

  return (
    <Grid item md={2} xs={12}>
      <Typography variant="body" component="legend" color="text.label">
        {T.COMPANY_NAME}
      </Typography>
      <MISTextField
        sx={{ mt: 0.5 }}
        required
        placeholder={T.COMPANY_NAME}
        size="small"
        variant="outlined"
        name="name"
        value={previousCompanies[index].name}
        helperText={companyExists ? "Company already exists" : ""}
        error={companyExists}
        onChange={(e) => onHandleMultiChange(e, index)}
      />
    </Grid>
  );
};

const WorkDetails = ({
  id = "",
  empStatus = "",
  isBytLead = false,
  clientJira = false,
  openPortfolioLink = true,
  jiraName = "",
  previousJiraName = "",
  projectMode = "",
  internalJiraExemption = false,
  trainingToBeAssigned = false,
  confirmJiraName = false,
  isRepMan = false,
  trainingName = "",
  trainingStartDate = null,
  trainingEndDate = null,
  secondarySkillTraining = {},
  comments = "",
  previousCompany = {},
  companyName = "",
  jiraFrequency = "",
  region = "",
  technologyDetails = [],
  portfolioLink = null,
  workingDays = [],
  weekDaysList = [],
  skillList = {},
  companyList = [],
  trainingList = {},
  userList = [],
  userLoading = false,
  shadowTo = {},
  handleWorkingDaysCheckboxChange = noop,
  onHandleChange = noop,
  onHandleDateChange = noop,
  onHandleTechAddMore = noop,
  onHandleTechChange = noop,
  onHandleTechRemove = noop,
  onHandleAutoCompleteChange = noop,
  onHandleNewAutoCompleteChange = noop,
  onHandleCheckboxChange = noop,
  onHandleTrainingChange = noop,
  handleJiraNameChange = noop,
  handleRevertChange = noop,
  onHandleCompanyChange = noop,
  previousCompanies = [],
  onHandlePrevCompAddMore = noop,
  onHandlePrevCompRemove = noop,
  onHandleMultiChange = noop,
  resetName = noop,
  setDuplicateCompany = noop,
}) => {
  const skillListResults = get(skillList, "results", []);
  const disableCondition = () => id && empStatus !== T.STABLE && empStatus !== T.YET_TO_JOIN && empStatus !== T.RESIGNED;
  const unselectedCompanies = companyList
    ?.filter((it) => it.companyName === "Other" || !previousCompanies?.some((comp) => it.id === comp.companyId))
    .sort(function (a, b) {
      if (a.companyName.toUpperCase() < b.companyName.toUpperCase()) {
        return -1;
      }
      if (a.companyName.toUpperCase() > b.companyName.toUpperCase()) {
        return 1;
      }
      return 0;
    });

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.JIRA_NAME}
          fullWidth
          placeholder={T.JIRA_NAME}
          size="small"
          disabled={disableCondition()}
          variant="outlined"
          name="jiraName"
          value={jiraName}
          onBlur={handleJiraNameChange}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.CLIENT_JIRA}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="clientJira"
          value={[clientJira]}
          onChange={onHandleChange}
        >
          {Object.keys(YES_NO_VALUES).map((key) => {
            return (
              <MenuItem value={YES_NO_VALUES[key]}>
                <Typography noWrap>{key}</Typography>
                {clientJira && <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />}
              </MenuItem>
            );
          })}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.INTERNAL_JIRA_EXEMPTION}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="internalJiraExemption"
          value={[internalJiraExemption]}
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>

          {Object.keys(YES_NO_VALUES).map((key) => (
            <MenuItem value={YES_NO_VALUES[key]}>
              <Typography noWrap>{key}</Typography>
              {internalJiraExemption && <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />}
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.PROJECT_MODE}
          listDetails={PROJECT_MODE}
          value={projectMode}
          disabled={disableCondition()}
          getByLabelText={(option) => option}
          onHandleChange={(event, newValue) => onHandleNewAutoCompleteChange("projectMode", newValue)}
        />
      </Grid>
      {projectMode === T.SHADOW && (
        <Grid item md={4} xs={12}>
          <MISAutocomplete
            label={T.SHADOW_TO}
            listDetails={userList}
            value={userList.find((item) => item.id === shadowTo)}
            loading={userLoading}
            disabled={disableCondition()}
            getByLabelText={(option) => get(option, "userEmailId", "")}
            onHandleChange={(event, newValue) => onHandleNewAutoCompleteChange("shadowTo", get(newValue, "id", ""))}
          />
        </Grid>
      )}
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.BYT_LEAD}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="isBytLead"
          value={[isBytLead]}
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>

          {Object.keys(YES_NO_VALUES).map((key) => (
            <MenuItem value={YES_NO_VALUES[key]}>
              <Typography noWrap>{key}</Typography>
              {isBytLead && <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />}
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.TRAINING_TO_BE_ASSIGNED}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="trainingToBeAssigned"
          value={[trainingToBeAssigned]}
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>

          {Object.keys(YES_NO_VALUES).map((key) => (
            <MenuItem value={YES_NO_VALUES[key]}>
              <Typography noWrap>{key}</Typography>
              {trainingToBeAssigned && <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />}
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>

      {trainingToBeAssigned && (
        <>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={`${T.TRAINING} ${T.NAME}`}
              fullWidth
              required
              disabled={disableCondition()}
              placeholder={`${T.TRAINING} ${T.NAME}`}
              size="small"
              variant="outlined"
              name="trainingName"
              value={trainingName}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISDatePicker
              label={`${T.TRAINING} ${T.START_DATE}`}
              placeholder={`${T.TRAINING} ${T.START_DATE}`}
              inputFormat="MM/DD/YYYY"
              disabled={disableCondition()}
              value={trainingStartDate}
              name="trainingStartDate"
              maxDate={trainingEndDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISDatePicker
              label={`${T.TRAINING} ${T.END_DATE}`}
              placeholder={`${T.TRAINING} ${T.END_DATE}`}
              inputFormat="MM/DD/YYYY"
              disabled={disableCondition()}
              value={trainingEndDate}
              name="trainingEndDate"
              minDate={trainingStartDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
        </>
      )}
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.SECONDARY_SKILL_SET_TRAINING}
          listDetails={skillListResults}
          value={skillListResults.find((skill) => skill.id === secondarySkillTraining.skillId) || {}}
          disabled={disableCondition()}
          getByLabelText={(option) => get(option, "skillName", "")}
          onHandleChange={(event, newValue) => onHandleTrainingChange("skillId", get(newValue, "id", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.COMMENTS}
          fullWidth
          placeholder={T.COMMENTS}
          size="small"
          disabled={disableCondition()}
          variant="outlined"
          name="comments"
          value={comments}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.FREQUENCY_OF_JIRA}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="jiraFrequency"
          value={jiraFrequency}
          // required
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>
          {JIRA_FREQUENCY.map((val) => (
            <MenuItem value={val}>
              <Typography noWrap>{val}</Typography>
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>

      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.REGION}
          fullWidth
          select
          disabled={disableCondition()}
          size="small"
          variant="outlined"
          name="region"
          value={[region]}
          onChange={onHandleChange}
        >
          <MenuItem value="">{T.SELECT_OPTION}</MenuItem>
          {REGION_LIST.map((val) => (
            <MenuItem value={val}>
              <Typography noWrap>{val}</Typography>
            </MenuItem>
          ))}
        </MISTextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={"Is Project Manager"}
          listDetails={YES_NO_VALUES_ARRAY}
          value={YES_NO_VALUES_ARRAY.find((item) => item.value === isRepMan)}
          disabled={disableCondition()}
          getByLabelText={(option) => get(option, "label", "")}
          onHandleChange={(event, newValue) => onHandleNewAutoCompleteChange("isRepMan", get(newValue, "value", false))}
        />
      </Grid>
      {[T.OTHER].includes(get(previousCompany, "companyName", "")) && (
        <Grid item md={4} xs={12}>
          <MISTextField
            label={T.COMPANY_NAME}
            fullWidth
            disabled={disableCondition()}
            placeholder={T.COMPANY_NAME}
            size="small"
            variant="outlined"
            name="companyName"
            value={companyName}
            onChange={onHandleChange}
          />
        </Grid>
      )}
      {technologyDetails.map((tech) => tech.skillId).includes(PORTFOLIO_LINK) && isUrl(portfolioLink) && (
        <Grid item md={3} xs={12}>
          <MISTextField
            label={`${T.PORTFOLIO} `}
            fullWidth
            placeholder={`${T.USER_PORTFOLIO} `}
            size="small"
            required
            disabled={disableCondition()}
            value={portfolioLink}
            variant="outlined"
            onChange={onHandleChange}
            name="portfolioLink"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <WithInputLabel label={T.WORKING_DAYS} required>
          <FormControl component="fieldset">
            <FormGroup row>
              {weekDaysList.map((val, index) => (
                <FormControlLabel
                  size="small"
                  name={val.label}
                  value={val.label}
                  disabled={disableCondition()}
                  control={<Checkbox />}
                  checked={workingDays.includes(val.label) ? !val.check : val.check}
                  label={val.label}
                  labelPlacement="end"
                  onChange={(event) => handleWorkingDaysCheckboxChange(event, index)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </WithInputLabel>
      </Grid>

      <Grid item xs={12}>
        <Typography
          fontSize={16}
          fontWeight={600}
          mt={2}
        >{`${T.PREVIOUS_COMPANY.toUpperCase()} ${T.DETAILS.toUpperCase()}`}</Typography>
      </Grid>

      <Grid item xs={12}>
        {Children.toArray(
          previousCompanies?.map((data, index) => (
            <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
              <Grid item md={4} xs={12}>
                <MISAutocomplete
                  label={`${T.COMPANY} ${T.NAME}`}
                  listDetails={unselectedCompanies.length > 0 ? unselectedCompanies : [{ id: 0, companyName: "No Options" }]}
                  value={companyList?.find((it) => it.id === previousCompanies[index].companyId) || {}}
                  disabled={disableCondition()}
                  getByLabelText={(option) => get(option, "companyName", "")}
                  onHandleChange={(event, newValue) => {
                    onHandleAutoCompleteChange(index, "companyName", get(newValue, "companyName", ""), T.PREVIOUS_COMPANY);

                    onHandleAutoCompleteChange(index, "companyId", get(newValue, "id", ""), T.PREVIOUS_COMPANY);
                  }}
                />
              </Grid>
              {previousCompanies[index].companyName === "Other" && (
                <PrevComp
                  resetName={resetName}
                  index={index}
                  previousCompanies={previousCompanies}
                  onHandleMultiChange={onHandleMultiChange}
                  companyList={companyList}
                  setDuplicateCompany={setDuplicateCompany}
                />
              )}
              <Grid item md={2} xs={12}>
                <Typography variant="body" component="legend" color="text.label">
                  {T.DURATION + " in Months"}
                </Typography>
                <MISTextField
                  sx={{ mt: 0.5 }}
                  required
                  placeholder={T.DURATION}
                  size="small"
                  variant="outlined"
                  name="duration"
                  InputProps={{ type: "number" }}
                  value={previousCompanies[index].duration ? previousCompanies[index].duration : ""}
                  onChange={(e) => onHandleMultiChange(e, index)}
                />
              </Grid>

              <Grid item md={1} xs={12}>
                {previousCompanies.length === 1 ? (
                  <AddCircleOutlineIcon
                    onClick={!disableCondition() && onHandlePrevCompAddMore}
                    fontSize="small"
                    sx={{
                      mt: 3.5,
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                ) : previousCompanies.length - 1 === index ? (
                  <>
                    <RemoveCircleOutlineIcon
                      onClick={!disableCondition() && (() => onHandlePrevCompRemove(index))}
                      fontSize="small"
                      sx={{
                        mt: 3.5,
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                    {unselectedCompanies.length > 0 && companyList.length !== previousCompanies.length && (
                      <AddCircleOutlineIcon
                        onClick={!disableCondition() && onHandlePrevCompAddMore}
                        fontSize="small"
                        sx={{
                          mt: 3.5,
                          cursor: "pointer",
                          color: NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <RemoveCircleOutlineIcon
                    onClick={!disableCondition() && (() => onHandlePrevCompRemove(index))}
                    fontSize="small"
                    sx={{
                      mt: 3.5,
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )),
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography
          fontSize={16}
          fontWeight={600}
          mt={2}
        >{`${T.TECHNOLOGY.toUpperCase()} ${T.DETAILS.toUpperCase()}`}</Typography>
      </Grid>

      <Grid item xs={12}>
        {Children.toArray(
          technologyDetails.map((data, index) => (
            <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
              <Grid item md={3} xs={12}>
                <MISAutocomplete
                  label={`${T.TECHNOLOGY} ${T.NAME}`}
                  listDetails={skillListResults.filter((skill) => !technologyDetails.some((tech) => skill.id === tech.skillId))}
                  value={skillListResults.find((skill) => skill.id === technologyDetails[index].skillId) || {}}
                  disabled={disableCondition()}
                  getByLabelText={(option) => get(option, "skillName", "")}
                  onHandleChange={(event, newValue) =>
                    onHandleAutoCompleteChange(index, "skillId", get(newValue, "id", ""), T.SKILL)
                  }
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography variant="body" component="legend" color="text.label">
                  {T.RATING}
                </Typography>
                <Rating
                  name="skillRating"
                  disabled={disableCondition()}
                  precision={0.5}
                  value={technologyDetails[index].skillRating}
                  sx={{ mt: 1.2 }}
                  onChange={(event) => onHandleTechChange(index, event)}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <MISAutocomplete
                  label={T.LEVEL}
                  listDetails={LEVEL_LIST}
                  value={LEVEL_LIST.find((item) => item.label === technologyDetails[index].level) || {}}
                  disabled={disableCondition()}
                  getByLabelText={(option) => get(option, "label", "")}
                  onHandleChange={(event, newValue) => onHandleTechChange(index, event, newValue, "level")}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Typography variant="body" component="legend" color="text.label">
                  {T.PRIMARY_SKILL}
                </Typography>
                <Checkbox
                  size="small"
                  sx={{ ml: 3 }}
                  disabled={disableCondition()}
                  name="isPrimary"
                  checked={technologyDetails[index].isPrimary}
                  onChange={(event) => onHandleCheckboxChange(index, event, T.TECHNOLOGY)}
                />
              </Grid>

              <Grid item md={1} xs={12}>
                {technologyDetails.length === 1 ? (
                  <AddCircleOutlineIcon
                    onClick={!disableCondition() && onHandleTechAddMore}
                    fontSize="small"
                    sx={{
                      mt: 3.5,
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                ) : technologyDetails.length - 1 === index ? (
                  <>
                    <RemoveCircleOutlineIcon
                      onClick={!disableCondition() && (() => onHandleTechRemove(index))}
                      fontSize="small"
                      sx={{
                        mt: 3.5,
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                    <AddCircleOutlineIcon
                      onClick={!disableCondition() && onHandleTechAddMore}
                      fontSize="small"
                      sx={{
                        mt: 3.5,
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                  </>
                ) : (
                  <RemoveCircleOutlineIcon
                    onClick={!disableCondition() && (() => onHandleTechRemove(index))}
                    fontSize="small"
                    sx={{
                      mt: 3.5,
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )),
        )}
      </Grid>
      {id && previousJiraName !== jiraName && (
        <JiraNameConfirm
          confirmJiraName={confirmJiraName}
          jiraName={jiraName}
          previousJiraName={previousJiraName}
          handleRevertChange={handleRevertChange}
          handleJiraNameChange={handleJiraNameChange}
        />
      )}
    </Grid>
  );
};

WorkDetails.propTypes = {
  id: PropTypes.bool,
  openPortfolioLink: PropTypes.bool,
  empStatus: PropTypes.string,
  jiraName: PropTypes.string,
  isBytLead: PropTypes.bool,
  clientJira: PropTypes.bool,
  internalJiraExemption: PropTypes.bool,
  trainingToBeAssigned: PropTypes.bool,
  projectMode: PropTypes.string,
  trainingName: PropTypes.string,
  trainingStartDate: PropTypes.instanceOf(Date),
  trainingEndDate: PropTypes.instanceOf(Date),
  comments: PropTypes.string,
  jiraFrequency: PropTypes.string,
  region: PropTypes.string,
  workingDays: PropTypes.array,
  weekDaysList: PropTypes.array,
  technologyDetails: PropTypes.array,
  portfolioLink: PropTypes.string,
  skillList: PropTypes.object,
  trainingList: PropTypes.object,
  onHandleChange: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  onHandleTechAddMore: PropTypes.func,
  onHandleTechRemove: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  onHandleCheckboxChange: PropTypes.func,
  handleWorkingDaysCheckboxChange: PropTypes.func,
  previousCompanies: PropTypes.func,
};

export default WorkDetails;
