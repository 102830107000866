import PropTypes from "prop-types";
import noop from "lodash/noop";
import dayjs from "dayjs";

import { Grid, Box, styled, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchBar from "components/Members/SearchBar";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import XLS from "assets/XLS.png";
import CSV from "assets/CSV.png";
import TrainingFilters from "../TrainingFilters";
import { GET_SIZE } from "utils/responsive";
import { canAddTraining, canReviewTraining, canViewTraining } from "utils/permissions";
import T from "T";
import MISButton from "components/common/MISButton";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import GroupsIcon from "@mui/icons-material/Groups";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const minDate = "2023-08-01";

const TopBar = ({
  value = "",
  searchInput = "",
  startDate = null,
  endDate = null,
  isFilterEmpty = false,
  showOptions = false,
  showCancelIcon = false,
  filters = {},
  searchTableData = {},
  projectManagerList = [],
  departmentList = [],
  courseList = [],
  subCourseList = [],
  userList = [],
  handleExport = noop,
  onClick = noop,
  handleKeyChange = noop,
  handleChange = noop,
  reset = noop,
  onClickOutside = noop,
  onHandleDateChange = noop,
  handleCourseAdd = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleFilterClose = noop,
  setTrainingState = noop,
}) => {
  const { isLg } = GET_SIZE();
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        mb: 1,
        pl: 3,
        pr: 3,
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          reset={reset}
          onClickOutside={onClickOutside}
        />
      </Grid>

      {(value === "1" || value === "2") && (
        <>
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={value === "1" ? T.CREATED_FROM : T.START_DATE}
              inputFormat="MM/DD/YYYY"
              value={startDate}
              minDate={dayjs(minDate)}
              name="startDate"
              maxDate={endDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
          -
          <Grid item md={2} xs={12}>
            <MISDatePicker
              placeholder={value === "1" ? T.CREATED_TO : T.END_DATE}
              inputFormat="MM/DD/YYYY"
              value={endDate}
              name="endDate"
              minDate={startDate}
              // maxDate={endDate}
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
        </>
      )}
      {value === "1" && (
        <Grid item md={1} xs={12}>
          <Tooltip title={T.ASSIGN_GROUP_TRAINING} placement="top">
            <GroupsIcon
              onClick={() => navigate(routes.app.groups)}
              sx={{
                mr: 1,
                height: 17,
                cursor: "pointer",
                color: NETSMARTZ_THEME_COLOR,
                width: 40,
                height: 40,
              }}
            />
          </Tooltip>
        </Grid>
      )}
      {value === "2" && (
        <Grid item md={2} xs={12}>
          <TrainingFilters
            filters={filters}
            projectManagerList={projectManagerList}
            departmentList={departmentList}
            courseList={courseList}
            subCourseList={subCourseList}
            userList={userList}
            isFilterEmpty={isFilterEmpty}
            handleFilterClose={handleFilterClose}
            handleFilterSubmit={handleFilterSubmit}
            onhandleFilterChange={onhandleFilterChange}
            setTrainingState={setTrainingState}
          />
        </Grid>
      )}
      {canReviewTraining() && !canViewTraining() && <Grid item md={2} xs={12}></Grid>}
      {canViewTraining() && value !== "3" && (
        <Grid item>
          <Box display="flex" alignItems="center">
            <Tooltip title={T.CSV} placement="top">
              <StyledBox component="img" src={CSV} onClick={() => handleExport(T.CSV)} />
            </Tooltip>
          </Box>
        </Grid>
      )}
      {value === "1" && canAddTraining() && (
        <Grid item>
          <MISButton size="medium" startIcon={<AddIcon sx={{ width: 19 }} />} onClick={() => handleCourseAdd()}>
            {T.ADD_NEW_COURSE}
          </MISButton>
        </Grid>
      )}
      {canAddTraining() && value === "2" && (
        <Grid item md={2} xs={12}>
          <MISButton size="medium" onClick={() => navigate(routes.app.groups)}>
            {T.ASSIGN_GROUP_TRAINING}
          </MISButton>
        </Grid>
      )}
    </Grid>
  );
};

TopBar.propTypes = {
  value: PropTypes.string,
  searchInput: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isFilterEmpty: PropTypes.bool,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  filters: PropTypes.object,
  searchTableData: PropTypes.object,
  projectManagerList: PropTypes.array,
  departmentList: PropTypes.array,
  courseList: PropTypes.array,
  subCourseList: PropTypes.array,
  userList: PropTypes.array,
  handleExport: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  handleChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  handleCourseAdd: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  setTrainingState: PropTypes.func,
};

export default TopBar;
