import React, { Children } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Grid, styled, Button, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { orange, grey } from "@mui/material/colors";
import T from "T";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const OrangeCheckbox = styled((props) => <Checkbox color="primary" {...props} />)({
  root: {
    color: orange[50],
  },
});

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "calc(100vh - 318px)",
  whiteSpace: "nowrap",
  overflow: "auto",
}));

const MisSubCourseConfigurator = ({ configData = [], handleOnDragEnd = noop, handleConfChange = noop }) => {
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="upper-section">
          {(provided) => (
            <Grid
              container
              spacing={0}
              wrap="nowrap"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Container>
                {Children.toArray(
                  configData &&
                    configData.map((data, index) => {
                      return (
                        <Draggable key={index} draggableId={`s${index}`} index={index}>
                          {(provide) => (
                            <Grid
                              container
                              wrap={"nowrap"}
                              spacing={1}
                              alignItems="center"
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                              data-id={data.position}
                            >
                              <Grid item xs={1}>
                                <MenuIcon fontSize="small" />
                              </Grid>
                              <Grid item xs={5}>
                                <FormControl component="fieldset">
                                  <FormControlLabel
                                    control={
                                      <OrangeCheckbox
                                        checked={configData[index].checked}
                                        onChange={() => {
                                          handleConfChange(index, "checked", configData);
                                        }}
                                        name={`checkedCol${index}`}
                                        sx={{
                                          paddingLeft: 3,
                                        }}
                                      />
                                    }
                                    label={data.label}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    }),
                )}
                {provided.placeholder}
              </Container>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

MisSubCourseConfigurator.propTypes = {
  configData: PropTypes.array,
  handleOnDragEnd: PropTypes.func,
  handleConfChange: PropTypes.func,
};

export default MisSubCourseConfigurator;
