import cloneDeep from "lodash/cloneDeep";
import { createSelector } from "reselect";

export const handleConfigurationChange = createSelector(
  [(state) => state.index, (state) => state.type, (state) => state.confData],
  (index, type, confData) => {
    let cloneConfData = cloneDeep(confData);
    cloneConfData[index][type] = !cloneConfData[index][type];
    return cloneConfData;
  },
);

export const handleConfiguratorDragEnd = createSelector(
  [(state) => state.result, (state) => state.subCourseData],
  (result, subCourseData) => {
    if (!result.destination) {
      return subCourseData;
    }

    const allSubCourses = Array.from(subCourseData);
    const [reorderedItem] = allSubCourses.splice(result.source.index, 1);
    allSubCourses.splice(result.destination.index, 0, reorderedItem);
    return allSubCourses;
  },
);
