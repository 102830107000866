import React, { Children } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { get } from "utils/lodash";
import { TableRow, TableCell, Typography, TableBody, styled, Rating, Tooltip, Badge, IconButton, Button } from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { MainTitleTypography, SubTitleTypography, TitleTypography } from "utils/members";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { capitalizeWords } from "utils/commonFunction";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import memo from "utils/react";
import T from "T";
import { MISCurrentUser } from "utils/validations";
import MISNoData from "components/common/MISNoData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const AssignedCourseTableBody = ({ allTableRows = [] }) => {
  const navigate = useNavigate();
  const handleTraineeCourseDetail = (id) => {
    const traineeCourse = allTableRows.find((item) => item.id === id);
    navigate(`/app/training/${id}/traineeCourse`, { state: traineeCourse });
  };
  // , {state: traineeCourse}
  const { user } = MISCurrentUser();
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const courseId = get(record, "id", "");
          const courseName = get(record, "trainingCourse.courseName", "");
          const courseType = get(record, "trainingCourse.assignmentType.courseAssignmentType", "");
          const subsCourses = get(record, "subsCourses", []);
          const courseDuration = get(record, "courseDuration", "");
          const assignDate = get(record, "assignDate", "");
          const endDate = get(record, "completionDate", "");
          const courseStartDate = get(record, "startDate", "");
          const courseEndDate = get(record, "endDate", "");
          const reviewDate = get(record, "reviewDate", "");
          const pendingDays = Math.ceil((new Date(endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={courseType} placement="top">
                  <TitleTypography>{capitalizeWords(courseName)}</TitleTypography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Tooltip title={courseType} placement="top">
                  <TitleTypography>{capitalizeWords(courseType)}</TitleTypography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                {subsCourses.length !== 0 && courseType !== T.OFFLINE_TRAINING
                  ? Children.toArray(
                      subsCourses.map((data, index) => {
                        const subsCourseName = get(data, "subCourse.subsCourseName", "");
                        return (
                          <Tooltip title={courseType} placement="top">
                            <TitleTypography>{capitalizeWords(subsCourseName)}</TitleTypography>
                          </Tooltip>
                        );
                      }),
                    )
                  : "N/A"}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                <TitleTypography>{courseDuration}Hrs</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <TitleTypography>{assignDate}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <TitleTypography>{endDate ? endDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <TitleTypography>{courseStartDate ? courseStartDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <TitleTypography>{courseEndDate ? courseStartDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <TitleTypography>{reviewDate ? reviewDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TitleTypography>{pendingDays <= -1 ? "Over" : pendingDays}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                <Button
                  size="small"
                  sx={{
                    bgcolor: NETSMARTZ_THEME_COLOR,
                    whiteSpace: "nowrap",
                    color: BACKGROUND.white,

                    "& .MuiButton-startIcon": {
                      mr: 0,
                    },
                    "&:hover": {
                      bgcolor: NETSMARTZ_THEME_COLOR,
                      color: BACKGROUND.white,
                    },
                  }}
                  onClick={() => handleTraineeCourseDetail(courseId)}
                >
                  {T.VIEW_DETAILS}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

AssignedCourseTableBody.propTypes = {
  allTableRows: PropTypes.array,
};

export default memo(AssignedCourseTableBody);
