import { ALL_PERMISSION_KEYS } from "settings/constants/roles";

import T from "T";
import { store } from "providers/store";
import { get } from "utils/lodash";

export const isHR = (role) => {
  return role === T.HR;
};

export const isTRAINEE = (role) => {
  return role === T.TRAINEE;
};

export const isPMO = (role) => role === T.PMO;

export const isSuperAdmin = (role) => role === T.SUPER_ADMIN;

export const isCorporateTrainer = (role) => role === T.CORPORATE_TRAINER;

const {
  DASHBOARD_VIEW_KEY,
  CASE_STUDY_VIEW_KEY,
  CASE_STUDY_ADD_KEY,
  CASE_STUDY_DELETE_KEY,
  CASE_STUDY_EDIT_KEY,
  REPORT_VIEW_KEY,
  REPORT_ADD_KEY,
  REPORT_EDIT_KEY,
  REPORT_DELETE_KEY,
  USER_VIEW_KEY,
  USER_ADD_KEY,
  USER_EDIT_KEY,
  USER_DELETE_KEY,
  ROLE_VIEW_KEY,
  ROLE_ADD_KEY,
  ROLE_EDIT_KEY,
  ROLE_DELETE_KEY,
  NC_VIEW_KEY,
  NC_ADD_KEY,
  NC_EDIT_KEY,
  NC_DELETE_KEY,
  NC_PM_FILTER,
  MASTER_SETTINGS_VIEW_KEY,
  MASTER_SETTINGS_ADD_KEY,
  MASTER_SETTINGS_EDIT_KEY,
  MASTER_SETTINGS_DELETE_KEY,
  ACCOUNTING_VIEW_KEY,
  ACCOUNTING_ADD_KEY,
  ACCOUNTING_EDIT_KEY,
  ACCOUNTING_DELETE_KEY,
  PRE_SALES_VIEW_KEY,
  PRE_SALES_ADD_KEY,
  PRE_SALES_EDIT_KEY,
  PRE_SALES_DELETE_KEY,
  TRAINING_VIEW_KEY,
  TRAINING_ADD_KEY,
  TRAINING_EDIT_KEY,
  TRAINING_DELETE_KEY,
  TRAINING_REVIEW_KEY,
  TRAINING_KRA_KEY,
  TRAINEE_VIEW_KEY,
  TESTIMONIAL_VIEW_KEY,
  TESTIMONIAL_DELETE_KEY,
  TESTIMONIAL_ADD_KEY,
  TESTIMONIAL_EDIT_KEY,
  INSIDE_SALES_VIEW_KEY,
  INSIDE_SALES_ADD_KEY,
  INSIDE_SALES_EDIT_KEY,
  INSIDE_SALES_DELETE_KEY,
  AI_ASSESSMENT_ADD_KEY,
  AI_ASSESSMENT_EDIT_KEY,
  AI_ASSESSMENT_VIEW_KEY,
  AI_ASSESSMENT_DELETE_KEY,
  MOCK_ASSESSMENT_VIEW_KEY,
  IS_TOOLS_VIEW_KEY,
  IS_TOOLS_ADD_KEY,
  IS_TOOLS_EDIT_KEY,
  IS_TOOLS_DELETE_KEY,
  REFERRAL_VIEW_KEY,
  REFERRAL_TA_KEY,
  REFERRAL_TA_VP_KEY,
  PROFILE_VIEW_KEY,
  TECHNICAL_INTERVIEW_ADD_KEY,
  TECHNICAL_INTERVIEW_VIEW_KEY,

  // TRACKER_VIEW_KEY,
} = ALL_PERMISSION_KEYS;

export const ACCESSES = () => {
  const state = store.getState();
  return get(state, "LoginSlice.accesses", []);
};

export const hasViewPermissions = (code) => code.includes("view");

export const hasAddPermissions = (code) => code.includes("add");

export const hasEditPermissions = (code) => code.includes("edit");

export const hasDeletePermissions = (code) => code.includes("delete");

export const hasViewPmFilterPermissions = (code) => code.includes("pm_filter");

export const hasReviewPermissions = (code) => code.includes("review");

export const hasKraPermission = (code) => code.includes("kra");

export const canViewDashboard = () => hasViewPermissions(DASHBOARD_VIEW_KEY) && ACCESSES().includes(DASHBOARD_VIEW_KEY);

export const canViewReport = () => hasViewPermissions(REPORT_VIEW_KEY) && ACCESSES().includes(REPORT_VIEW_KEY);

export const canAddReport = () => hasAddPermissions(REPORT_ADD_KEY) && ACCESSES().includes(REPORT_ADD_KEY);

export const canEditReport = () => hasEditPermissions(REPORT_EDIT_KEY) && ACCESSES().includes(REPORT_EDIT_KEY);

export const canDeleteReport = () => hasDeletePermissions(REPORT_DELETE_KEY) && ACCESSES().includes(REPORT_DELETE_KEY);

export const canViewUser = () => hasViewPermissions(USER_VIEW_KEY) && ACCESSES().includes(USER_VIEW_KEY);

export const canAddUser = () => hasAddPermissions(USER_ADD_KEY) && ACCESSES().includes(USER_ADD_KEY);

export const canViewFeedback = () => hasAddPermissions(USER_ADD_KEY) && ACCESSES().includes(USER_ADD_KEY);

export const canEditUser = () => hasEditPermissions(USER_EDIT_KEY) && ACCESSES().includes(USER_EDIT_KEY);

export const canDeleteUser = () => hasDeletePermissions(USER_DELETE_KEY) && ACCESSES().includes(USER_DELETE_KEY);

export const canViewRole = () => hasViewPermissions(ROLE_VIEW_KEY) && ACCESSES().includes(ROLE_VIEW_KEY);

export const canAddRole = () => hasAddPermissions(ROLE_ADD_KEY) && ACCESSES().includes(ROLE_ADD_KEY);

export const canEditRole = () => hasEditPermissions(ROLE_EDIT_KEY) && ACCESSES().includes(ROLE_EDIT_KEY);

export const canDeleteRole = () => hasDeletePermissions(ROLE_DELETE_KEY) && ACCESSES().includes(ROLE_DELETE_KEY);

export const canViewNC = () => hasViewPermissions(NC_VIEW_KEY) && ACCESSES().includes(NC_VIEW_KEY);

export const canAddNC = () => hasAddPermissions(NC_ADD_KEY) && ACCESSES().includes(NC_ADD_KEY);

export const canEditNC = () => hasEditPermissions(NC_EDIT_KEY) && ACCESSES().includes(NC_EDIT_KEY);

export const canDeleteNC = () => hasDeletePermissions(NC_DELETE_KEY) && ACCESSES().includes(NC_DELETE_KEY);

export const canViewPmFilter = () => hasViewPmFilterPermissions(NC_PM_FILTER) && ACCESSES().includes(NC_PM_FILTER);

export const canViewCaseStudy = () => hasViewPermissions(CASE_STUDY_VIEW_KEY) && ACCESSES().includes(CASE_STUDY_VIEW_KEY);

export const canAddCaseStudy = () => hasAddPermissions(CASE_STUDY_ADD_KEY) && ACCESSES().includes(CASE_STUDY_ADD_KEY);

export const canEditCaseStudy = () => hasEditPermissions(CASE_STUDY_EDIT_KEY) && ACCESSES().includes(CASE_STUDY_EDIT_KEY);

export const canDeleteCaseStudy = () => hasDeletePermissions(CASE_STUDY_DELETE_KEY) && ACCESSES().includes(CASE_STUDY_DELETE_KEY);

export const canViewTestimonial = () => hasViewPermissions(TESTIMONIAL_VIEW_KEY) && ACCESSES().includes(TESTIMONIAL_VIEW_KEY);

export const canAddTestimonial = () => hasAddPermissions(TESTIMONIAL_ADD_KEY) && ACCESSES().includes(TESTIMONIAL_ADD_KEY);

export const canDeleteTestimonial = () =>
  hasDeletePermissions(TESTIMONIAL_DELETE_KEY) && ACCESSES().includes(TESTIMONIAL_DELETE_KEY);

export const canEditTestimonial = () => hasEditPermissions(TESTIMONIAL_EDIT_KEY) && ACCESSES().includes(TESTIMONIAL_EDIT_KEY);

export const canViewMasterSettings = () =>
  hasViewPermissions(MASTER_SETTINGS_VIEW_KEY) && ACCESSES().includes(MASTER_SETTINGS_VIEW_KEY);

export const canAddMasterSettings = () =>
  hasAddPermissions(MASTER_SETTINGS_ADD_KEY) && ACCESSES().includes(MASTER_SETTINGS_ADD_KEY);

export const canEditMasterSettings = () =>
  hasEditPermissions(MASTER_SETTINGS_EDIT_KEY) && ACCESSES().includes(MASTER_SETTINGS_EDIT_KEY);

export const canDeleteMasterSettings = () =>
  hasDeletePermissions(MASTER_SETTINGS_DELETE_KEY) && ACCESSES().includes(MASTER_SETTINGS_DELETE_KEY);

export const canViewAccounting = () => hasViewPermissions(ACCOUNTING_VIEW_KEY) && ACCESSES().includes(ACCOUNTING_VIEW_KEY);

export const canAddAccounting = () => hasAddPermissions(ACCOUNTING_ADD_KEY) && ACCESSES().includes(ACCOUNTING_ADD_KEY);

export const canEditAccounting = () => hasEditPermissions(ACCOUNTING_EDIT_KEY) && ACCESSES().includes(ACCOUNTING_EDIT_KEY);

export const canDeleteAccounting = () =>
  hasDeletePermissions(ACCOUNTING_DELETE_KEY) && ACCESSES().includes(ACCOUNTING_DELETE_KEY);

export const canViewPreSales = () => hasViewPermissions(PRE_SALES_VIEW_KEY) && ACCESSES().includes(PRE_SALES_VIEW_KEY);

export const canAddPreSales = () => hasAddPermissions(PRE_SALES_ADD_KEY) && ACCESSES().includes(PRE_SALES_ADD_KEY);

export const canEditPreSales = () => hasEditPermissions(PRE_SALES_EDIT_KEY) && ACCESSES().includes(PRE_SALES_EDIT_KEY);

export const canDeletePreSales = () => hasDeletePermissions(PRE_SALES_DELETE_KEY) && ACCESSES().includes(PRE_SALES_DELETE_KEY);

export const canViewTraining = () => hasViewPermissions(TRAINING_VIEW_KEY) && ACCESSES().includes(TRAINING_VIEW_KEY);

export const canAddTraining = () => hasAddPermissions(TRAINING_ADD_KEY) && ACCESSES().includes(TRAINING_ADD_KEY);

export const canEditTraining = () => hasEditPermissions(TRAINING_EDIT_KEY) && ACCESSES().includes(TRAINING_EDIT_KEY);

export const canReviewTraining = () => hasReviewPermissions(TRAINING_REVIEW_KEY) && ACCESSES().includes(TRAINING_REVIEW_KEY);

export const canKraTraining = () => hasKraPermission(TRAINING_KRA_KEY) && ACCESSES().includes(TRAINING_KRA_KEY);

export const canViewTrainee = () => hasViewPermissions(TRAINEE_VIEW_KEY) && ACCESSES().includes(TRAINEE_VIEW_KEY);

export const canViewInsideSales = () => hasViewPermissions(INSIDE_SALES_VIEW_KEY) && ACCESSES().includes(INSIDE_SALES_VIEW_KEY);

export const canAddInsideSales = () => hasAddPermissions(INSIDE_SALES_ADD_KEY) && ACCESSES().includes(INSIDE_SALES_ADD_KEY);

export const canEditInsideSales = () => hasEditPermissions(INSIDE_SALES_EDIT_KEY) && ACCESSES().includes(INSIDE_SALES_EDIT_KEY);

export const canAddTechAssignment = () => hasAddPermissions(AI_ASSESSMENT_ADD_KEY) && ACCESSES().includes(AI_ASSESSMENT_ADD_KEY);
export const canEditTechAssignment = () =>
  hasEditPermissions(AI_ASSESSMENT_EDIT_KEY) && ACCESSES().includes(AI_ASSESSMENT_EDIT_KEY);
export const hasViewTechAssignmentPermissions = (code) => code.includes("list");
export const canViewTechAssignment = () =>
  hasViewTechAssignmentPermissions(AI_ASSESSMENT_VIEW_KEY) && ACCESSES().includes(AI_ASSESSMENT_VIEW_KEY);
export const canDeleteTechAssignment = () =>
  hasDeletePermissions(AI_ASSESSMENT_DELETE_KEY) && ACCESSES().includes(AI_ASSESSMENT_DELETE_KEY);

export const canDeleteInsideSales = () =>
  hasDeletePermissions(INSIDE_SALES_DELETE_KEY) && ACCESSES().includes(INSIDE_SALES_DELETE_KEY);

export const canViewISTools = () => hasViewPermissions(IS_TOOLS_VIEW_KEY) && ACCESSES().includes(IS_TOOLS_VIEW_KEY);

export const canAddISTools = () => hasAddPermissions(IS_TOOLS_ADD_KEY) && ACCESSES().includes(IS_TOOLS_ADD_KEY);

export const canEditISTools = () => hasEditPermissions(IS_TOOLS_EDIT_KEY) && ACCESSES().includes(IS_TOOLS_EDIT_KEY);

export const canDeleteISTools = () => hasDeletePermissions(IS_TOOLS_DELETE_KEY) && ACCESSES().includes(IS_TOOLS_DELETE_KEY);

export const canViewReferral = () => hasViewPermissions(REFERRAL_VIEW_KEY) && ACCESSES().includes(REFERRAL_VIEW_KEY);

// export const canTrackerView = () => hasViewPermissions(TRACKER_VIEW_KEY) && ACCESSES().includes(TRACKER_VIEW_KEY);

export const canViewMockAssessment = () =>
  hasViewPermissions(MOCK_ASSESSMENT_VIEW_KEY) && ACCESSES().includes(MOCK_ASSESSMENT_VIEW_KEY);

export const canViewRecommendationsTab = () => hasViewPermissions(REFERRAL_TA_VP_KEY) && ACCESSES().includes(REFERRAL_TA_VP_KEY);
export const canViewRecommendations = () => hasViewPermissions(REFERRAL_TA_KEY) && ACCESSES().includes(REFERRAL_TA_KEY);
export const canViewProfile = () => hasViewPermissions(PROFILE_VIEW_KEY) && ACCESSES().includes(PROFILE_VIEW_KEY);
export const canViewTechnicalInterview = () =>
  hasViewPermissions(TECHNICAL_INTERVIEW_VIEW_KEY) && ACCESSES().includes(TECHNICAL_INTERVIEW_VIEW_KEY);
export const canAddTechnicalInterview = () =>
  hasAddPermissions(TECHNICAL_INTERVIEW_ADD_KEY) && ACCESSES().includes(TECHNICAL_INTERVIEW_ADD_KEY);
