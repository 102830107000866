import { Box } from "@mui/system";
import MISFooterButton from "components/common/MISFooterButton";
import { capitalize, get } from "lodash";
import { useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import T from "T";
import CreateCourse from "./CreateCourse";
import { useLazyGetPreSalesManagersQuery } from "api/preSales/getPreSalesManagers";
import { useSaveCourseMutation } from "api/training/courseDetail/addCourse";
import { useLazyGetCourseByIdQuery } from "api/training/courseDetail/getCourseById";
import { useUpdateCourseMutation } from "api/training/courseDetail/updateCourse";
import { completionTypeListing } from "../trainingModal";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmCancel from "../ConfirmCancel";
import { isAsstUrl, isNumber, isUrl } from "utils/validations";
import { useLazyCourseTypesQuery } from "api/training/typeOfCourse/courseType";
import { turnToArray } from "utils/commonFunction";
import { useLazyGetAllDepartmentQuery } from "api/members/getDepartmentList";
import { useLazyGetCourseListQuery } from "api/training/courseDetail/getCourseList";
import { filtersStore } from "slices/filtersSlice";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    courseName: "",
    typeId: "",
    department: null,
    courseTypeName: "",
    courseDuration: "",
    practicalDuration: "",
    meetingLink: "",
    selectOfflineAssignment: false,
    offlineAssignment: {
      offlineAssignmentLink: "",
      offlineAssignmentFile: "",
      offlineAssignmentFileName: "",
      showOfflineFileName: "",
      offlineCompletionTypeId: "",
      offlineCompletionType: "",
    },
    updateOfflineAssignment: {
      offlineCourseId: "",
      offlineLinksId: "",
      offlineAssignmentLink: "",
      offlineAssignmentFile: "",
      offlineAssignmentFileName: "",
      showOfflineFileName: "",
      offlineCompletionTypeId: "",
      offlineCompletionType: "",
    },
    subCourses: [
      {
        completionTypeId: "",
        completionType: "",
        subCourseName: "",
        subCourseDescription: "",
        subCourseAssessmentLink: "",
        courseLinks: "",
        courseLinksArray: [],
        file: "",
        fileName: "",
        assessmentFile: "",
        assessmentFileName: "",
        showSubCourseFileName: "",
        showAssessmentFileName: "",
      },
    ],
    updateSubCourses: [
      {
        completionTypeId: "",
        completionType: "",
        subCourseId: "",
        resourceLinksId: "",
        subCourseName: "",
        subCourseDescription: "",
        subCourseAssessmentLink: "",
        courseLinks: "",
        courseLinksArray: [],
        file: "",
        fileName: "",
        assessmentFile: "",
        assessmentFileName: "",
        showSubCourseFileName: "",
        showAssessmentFileName: "",
      },
    ],
    courseFile: {
      courseFileName: "",
      file: "",
    },
    savedSubCourses: [],
  });

  const {
    courseName,
    typeId,
    department,
    courseTypeName,
    courseDescription, // removed as per mam call
    assessmentLink, // removed as per mam call
    courseDuration,
    practicalDuration,
    meetingLink,
    selectOfflineAssignment,
    offlineAssignment,
    updateOfflineAssignment,
    courseFile,
    subCourses,
    updateSubCourses,
    savedSubCourses,
    openConfirmCancel,
  } = localState;

  const coursesArray = id ? updateSubCourses : subCourses;
  const offlineCourseArray = id ? updateOfflineAssignment : offlineAssignment;
  const stateKey = id ? "updateSubCourses" : "subCourses";
  const offlineKey = id ? "updateOfflineAssignment" : "offlineAssignment";
  const [saveCourse] = useSaveCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [getPreSalesManagers, { data: preSalesManagersList }] = useLazyGetPreSalesManagersQuery();
  const [getCourseById] = useLazyGetCourseByIdQuery();
  const [getAllDepartment, { data: departmentList, isFetching: departmentLoading }] = useLazyGetAllDepartmentQuery();
  const [courseTypes, { data: courseTypesListing }] = useLazyCourseTypesQuery();
  const [getCourseList] = useLazyGetCourseListQuery();
  const transformResponseData = (res) => {
    const {
      courseName,
      department,
      description,
      assessmentResourceLink,
      courseDuration,
      subsCourses,
      assignmentType,
      practicalDuration,
    } = res;
    const subCourseCheck = subsCourses.length > 0;
    const completionTypeIds = completionTypeListing.map((item) => item.completionTypeId);
    const assignmentTypeName = get(assignmentType, "courseAssignmentType", "");
    const offlineCompletionTypeId = subCourseCheck ? get(subsCourses[0]["resourceLink"][0], "completionType", null) : null;
    const offlineCompletionCheck = completionTypeIds.includes(offlineCompletionTypeId) ? true : false;
    const offlineCourseId = subCourseCheck ? get(subsCourses[0], "training_sub_course_id", "") : null;
    const offlineLinksId = subCourseCheck ? get(subsCourses[0]["resourceLink"][0], "training_resource_link_id", "") : null;
    const offlineAssessmentLink = subCourseCheck ? get(subsCourses[0], "assessmentResourceLink", "") : "";
    const offlineAssessmentFile = subCourseCheck ? get(subsCourses[0], "assessmentFileLink") : "";
    const offlineAssessmentFileLinkName = offlineAssessmentFile ? offlineAssessmentFile.split("/").pop().split("_").pop() : "";

    const transformSubCourse = (data) => {
      const { training_sub_course_id, subsCourseName, description, assessmentResourceLink, assessmentFileLink, resourceLink } =
        data;
      const completionTypeId = resourceLink[0].completionType;
      const resourceLinks = resourceLink[0].resourceLink ? turnToArray(resourceLink[0].resourceLink) : [];
      const resourceLinksId = resourceLink[0].training_resource_link_id;
      const resourseFileLink = resourceLink[0].fileLink ? resourceLink[0].fileLink : "";
      const assessmentFile = assessmentFileLink ? assessmentFileLink : "";
      const resourseFileLinkName = resourseFileLink.split("/").pop().split("_").pop();
      const assessmentFileLinkName = assessmentFile.split("/").pop().split("_").pop();

      return {
        completionTypeId: completionTypeId,
        subCourseId: training_sub_course_id,
        subCourseName: subsCourseName,
        subCourseDescription: description,
        resourceLinksId: resourceLinksId,
        subCourseAssessmentLink: assessmentResourceLink,
        courseLinksArray: resourceLinks,
        file: "",
        fileName: "",
        assessmentFile: "",
        assessmentFileName: "",
        showSubCourseFileName: resourseFileLinkName,
        showAssessmentFileName: assessmentFileLinkName,
      };
    };

    return {
      courseName,
      department,
      courseDescription: description,
      typeId: assignmentType.courseAssignmentTypeId,
      courseTypeName: assignmentType.courseAssignmentType,
      courseDuration,
      practicalDuration,
      meetingLink: assessmentResourceLink,
      selectOfflineAssignment: assignmentTypeName === T.OFFLINE_TRAINING && offlineCompletionCheck,
      updateOfflineAssignment:
        assignmentTypeName === T.OFFLINE_TRAINING && offlineCompletionCheck && subCourseCheck
          ? {
              offlineCourseId: offlineCourseId,
              offlineLinksId: offlineLinksId,
              offlineAssignmentLink: offlineAssessmentLink,
              offlineAssignmentFile: "",
              offlineAssignmentFileName: "",
              showOfflineFileName: offlineAssessmentFileLinkName,
              offlineCompletionTypeId: offlineCompletionTypeId,
              offlineCompletionType: "",
            }
          : {},
      updateSubCourses: subsCourses.map(transformSubCourse),
      courseFile: {
        courseFileName: "",
        file: "",
      },
      savedSubCourses: subsCourses.map(transformSubCourse),
    };
  };

  useEffect(() => {
    if (id) {
      getCourseById({ id })
        .unwrap()
        .then((res) => {
          const prevRequirements = transformResponseData(res);
          setLocalState(prevRequirements);
        })
        .catch(handleError);
    }
  }, [id]);

  const emptyCoursesRequirements = {
    subCourseName: "",
    completionTypeId: "",
    completionType: "",
    subCourseDescription: "",
    subCourseAssessmentLink: "",
    courseLinks: "",
    courseLinksArray: [],
    file: "",
    fileName: "",
    assessmentFile: "",
    assessmentFileName: "",
    showSubCourseFileName: "",
    showAssessmentFileName: "",
  };

  const emptyUpdateCourseRequirements = {
    subCourseId: null,
    subCourseName: "",
    completionTypeId: "",
    completionType: "",
    subCourseDescription: "",
    resourceLinksId: null,
    subCourseAssessmentLink: "",
    courseLinks: "",
    courseLinksArray: [],
    file: "",
    fileName: "",
    assessmentFile: "",
    assessmentFileName: "",
    showSubCourseFileName: "",
    showAssessmentFileName: "",
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const handleCourseType = (event, value, type) => {
    if (type === "courseType") {
      if (get(value, "courseAssignmentType", "") !== T.OFFLINE_TRAINING) {
        setLocalState({ selectOfflineAssignment: false });
      }
      setLocalState({
        typeId: get(value, "courseAssignmentTypeId", ""),
        courseTypeName: get(value, "courseAssignmentType", ""),
      });
    } else {
      setLocalState({ department: value });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleSubCourseFileUpload = async (event, index) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    coursesArray[index][name] = fileName;
    coursesArray[index]["file"] = base64;
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const handleSubCourseAssessmentFileUpload = async (event, index) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    coursesArray[index][name] = fileName;
    coursesArray[index]["assessmentFile"] = base64;
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const handleCompletionType = (event, value, index) => {
    coursesArray[index]["completionTypeId"] = get(value, "completionTypeId", "");
    coursesArray[index]["completionType"] = get(value, "completionType", "");
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const onHandleRequirementsChange = (event, index) => {
    const { name, value } = event.target;
    coursesArray[index][name] = value;
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const onHandleReqAddMore = () => {
    const emptyCourseRequirements = id ? emptyUpdateCourseRequirements : emptyCoursesRequirements;
    coursesArray.push(emptyCourseRequirements);
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const onHandleReqRemove = (index) => {
    coursesArray.splice(index, 1);
    setLocalState({ [stateKey]: [...coursesArray] });
  };

  const handleKeyPress = (e, index) => {
    const { value } = e.target;
    if (e.key === "Enter" || e.code === "Enter") {
      const existingLinks = coursesArray[index]["courseLinksArray"] || [];
      if (!isUrl(value)) {
        toast.error(T.INVALID_URL_FORMAT);
      } else if (!existingLinks.includes(value)) {
        coursesArray[index]["courseLinksArray"] = [value, ...existingLinks];
        coursesArray[index]["courseLinks"] = "";
        setLocalState({ [stateKey]: [...coursesArray] });
      }
    }
  };

  const handleButtonClick = (value, index) => {
    if (value === "") {
      return;
    } else if (!isUrl(value)) {
      toast.error(T.INVALID_URL_FORMAT);
    } else {
      const existingLinks = coursesArray[index]["courseLinksArray"] || [];
      if (!existingLinks.includes(value)) {
        coursesArray[index]["courseLinksArray"] = [value, ...existingLinks];
        coursesArray[index]["courseLinks"] = "";
        setLocalState({ [stateKey]: [...coursesArray] });
      }
    }
  };

  const handleDeleteChip = (index, chipIndex) => {
    const updateCoursesArray = (courses, index, chipIndex) =>
      courses.map((course, i) => {
        if (i === index) {
          const updatedLinksArray = course.courseLinksArray.filter((_, j) => j !== chipIndex);
          return { ...course, courseLinksArray: updatedLinksArray };
        }
        return course;
      });
    setLocalState({ [stateKey]: updateCoursesArray(coursesArray, index, chipIndex) });
  };

  const handleSelectOfflineAssignment = (event) => {
    const { checked } = event.target;
    if (selectOfflineAssignment) {
      setLocalState({ offlineAssignment: {}, updateOfflineAssignment: {} });
    }
    setLocalState({ selectOfflineAssignment: checked });
  };

  const handleOfflineLink = (event) => {
    const { name, value } = event.target;
    offlineCourseArray[name] = value;
    setLocalState({ [offlineKey]: { ...offlineCourseArray } });
  };

  const handleOfflineCompletionType = (event, value) => {
    offlineCourseArray["offlineCompletionTypeId"] = get(value, "completionTypeId", "");
    offlineCourseArray["offlineCompletionType"] = get(value, "completionType", "");
    setLocalState({ [offlineKey]: { ...offlineCourseArray } });
  };

  const handleOfflineFileUpload = async (event, value) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    offlineCourseArray[name] = fileName;
    offlineCourseArray["offlineAssignmentFile"] = base64;
    setLocalState({ [offlineKey]: { ...offlineCourseArray } });
  };

  const handleDeleteFile = (event) => {
    const { name } = event.target;
    offlineCourseArray[name] = "";
    offlineCourseArray["offlineAssignmentFile"] = "";
    setLocalState({ [offlineKey]: { ...offlineCourseArray } });
  };

  useEffect(() => {
    courseTypes();
    getAllDepartment({ page: 0, rowPerPage: 10000 });
  }, []);

  const handleClose = () => {
    navigate(-1);
  };

  const renderNewCourseDetails = () => ({
    id,
    courseName,
    typeId,
    courseTypeName,
    courseDescription,
    assessmentLink,
    courseDuration,
    practicalDuration,
    meetingLink,
    selectOfflineAssignment,
    offlineAssignment: id ? updateOfflineAssignment : offlineAssignment,
    subCourses: id ? updateSubCourses : subCourses,
    savedSubCourses,
    preSalesManagersList,
    courseTypesListing,
    completionTypeListing,
    department,
    departmentList: get(departmentList, "results", []),
    departmentLoading,
    handleOfflineLink,
    handleSelectOfflineAssignment,
    handleOfflineCompletionType,
    handleOfflineFileUpload,
    handleDeleteFile,
    handleDeleteChip,
    handleSubCourseFileUpload,
    handleSubCourseAssessmentFileUpload,
    getPreSalesManagers,
    onHandleReqAddMore,
    onHandleReqRemove,
    onHandleChange,
    handleCourseType,
    handleCompletionType,
    onHandleRequirementsChange,
    handleKeyPress,
    handleButtonClick,
  });

  const offlineCourseRequirements = () => {
    let reqmt = [];
    reqmt.push({
      assessmentResourceLink: get(offlineAssignment, "offlineAssignmentLink", ""),
      assessmentFileLink: get(offlineAssignment, "offlineAssignmentFile", ""),
      fileType: get(offlineAssignment, "offlineAssignmentFileName", ""),
      resourceLinks: {
        completionType: get(offlineAssignment, "offlineCompletionTypeId", ""),
        urlLinks: [],
      },
    });
    return reqmt;
  };

  const updateOfflineCourseRequirements = () => {
    let reqmt = [];
    reqmt.push({
      subCourseId: get(updateOfflineAssignment, "offlineCourseId", ""),
      assessmentResourceLink: get(updateOfflineAssignment, "offlineAssignmentLink", ""),
      assessmentFileLink: get(updateOfflineAssignment, "offlineAssignmentFile", ""),
      fileType: get(updateOfflineAssignment, "offlineAssignmentFileName", ""),
      resourceLinks: {
        resourceLinkId: get(updateOfflineAssignment, "offlineLinksId", ""),
        completionType: get(updateOfflineAssignment, "offlineCompletionTypeId", ""),
        urlLinks: [],
      },
    });
    return reqmt;
  };
  const getCourseRequirements = () => {
    let subCourse = [];
    subCourses.map((req) => {
      subCourse.push({
        subCourseName: get(req, "subCourseName", ""),
        assessmentResourceLink: get(req, "subCourseAssessmentLink", ""),
        assessmentFileLink: get(req, "assessmentFile", ""),
        fileType: get(req, "assessmentFileName", ""),
        description: get(req, "subCourseDescription", ""),
        resourceLinks: {
          completionType: get(req, "completionTypeId", ""),
          fileLink: get(req, "file", ""),
          fileType: get(req, "fileName", ""),
          urlLinks: get(req, "courseLinksArray", []),
        },
      });
      return subCourse;
    });
    return subCourse;
  };

  const getUpdateCourseRequirements = () => {
    let subCourse = [];
    updateSubCourses.map((req) => {
      subCourse.push({
        subCourseId: get(req, "subCourseId", ""),
        subCourseName: get(req, "subCourseName", ""),
        assessmentResourceLink: get(req, "subCourseAssessmentLink", ""),
        assessmentFileLink: get(req, "assessmentFile", ""),
        fileType: get(req, "assessmentFileName", ""),
        description: get(req, "subCourseDescription", ""),
        resourceLinks: {
          completionType: get(req, "completionTypeId", ""),
          resourceLinkId: get(req, "resourceLinksId", ""),
          fileLink: get(req, "file", ""),
          fileType: get(req, "fileName", ""),
          urlLinks: get(req, "courseLinksArray", []),
        },
      });
      return subCourse;
    });
    return subCourse;
  };

  const validation = () => {
    if (!courseTypeName) {
      toast.error("Course Type is Empty");
      return false;
    }

    if (!courseName.trim()) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return false;
    }

    if (!department) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return false;
    }

    if (courseTypeName !== T.OFFLINE_TRAINING) {
      if (
        (id && !updateSubCourses.every((item) => item.subCourseName.trim())) ||
        (!id && !subCourses.every((item) => item.subCourseName.trim()))
      ) {
        toast.error(T.REQUIRED_FIELDS_EMPTY);
        return false;
      }

      if (
        (id && !updateSubCourses.every((item) => item.completionTypeId !== "")) ||
        (!id && !subCourses.every((item) => item.completionType.trim()))
      ) {
        toast.error(T.REQUIRED_FIELDS_EMPTY);
        return false;
      }

      if (
        (id &&
          !updateSubCourses.every(
            (item) => !item.subCourseAssessmentLink || (item.subCourseAssessmentLink && isUrl(item.subCourseAssessmentLink)),
          )) ||
        (!id &&
          !subCourses.every(
            (item) => !item.subCourseAssessmentLink || (item.subCourseAssessmentLink && isUrl(item.subCourseAssessmentLink)),
          ))
      ) {
        toast.error(T.VALID_URL_LINK);
        return false;
      }
    }

    if (courseDuration && !isNumber(courseDuration)) {
      toast.error(T.VALID_COURSE_DURATION);
      return false;
    }

    if (practicalDuration && !isNumber(practicalDuration)) {
      toast.error(T.VALID_PRACTICAL_DURATION);
      return false;
    }

    if (meetingLink && !isUrl(meetingLink)) {
      toast.error(T.VALID_URL_LINK);
      return false;
    }

    if (
      (offlineAssignment["offlineAssignmentLink"] && !isUrl(offlineAssignment["offlineAssignmentLink"])) ||
      (updateOfflineAssignment["offlineAssignmentLink"] && !isUrl(updateOfflineAssignment["offlineAssignmentLink"]))
    ) {
      toast.error(T.VALID_URL_LINK);
      return false;
    }

    if (courseDuration > 365) {
      toast.error(T.COURSE_DURATION_MORE_THAN_365);
      return false;
    }

    if (courseTypeName === T.OFFLINE_TRAINING) {
      if (selectOfflineAssignment) {
        if (
          (id && !toString(updateOfflineAssignment.offlineCompletionTypeId)) ||
          (!id && !offlineAssignment.offlineCompletionType)
        ) {
          toast.error(T.REQUIRED_FIELDS_EMPTY);
          return false;
        }
      }
    }

    return true;
  };

  const refreshCourse = () => {
    getCourseList({ page: 0, rowPerPage: 10000 })
      .unwrap()
      .then((res) => {
        dispatch(filtersStore({ courseList: res }));
      })
      .catch(handleError);
  };

  const handleSubmit = () => {
    if (!validation()) {
      return;
    } else {
      const courseRequirements = getCourseRequirements();
      const offlineCourseReq = offlineCourseRequirements();
      const payload = {
        courseName: courseName,
        departmentId: get(department, "id", ""),
        courseDuration: courseDuration ? parseInt(courseDuration) : null,
        practicalDuration: practicalDuration ? parseInt(practicalDuration) : null,
        assignmentType: parseInt(typeId),
        assessmentResourceLink: courseTypeName === T.OFFLINE_TRAINING ? meetingLink : null,
        subsequentCourses:
          courseTypeName !== T.OFFLINE_TRAINING ? courseRequirements : selectOfflineAssignment ? offlineCourseReq : null,
      };
      saveCourse(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.COURSE_ADDED_SUCCESSFULLY);
          refreshCourse();
          handleClose();
        })
        .catch(handleError);
    }
  };

  const handleUpdate = () => {
    if (!validation()) {
      return;
    } else {
      const courseRequirements = getUpdateCourseRequirements();
      const offlineCourseReq = updateOfflineCourseRequirements();
      const payload = {
        courseId: id,
        courseName: courseName,
        departmentId: get(department, "id", null),
        assignmentType: parseInt(typeId),
        practicalDuration: practicalDuration ? parseInt(practicalDuration) : 0,
        courseDuration: parseInt(courseDuration),
        description: courseDescription,
        assessmentFileLink: courseFile.file,
        fileType: courseFile.courseFileName,
        assessmentResourceLink: courseTypeName === T.OFFLINE_TRAINING ? meetingLink : null,
        subsequentCourses:
          courseTypeName !== T.OFFLINE_TRAINING ? courseRequirements : selectOfflineAssignment ? offlineCourseReq : [],
      };
      updateCourse({ courseDto: payload })
        .unwrap()
        .then((res) => {
          toast.success(T.COURSE_UPDATE_SUCCESSFULLY);
          refreshCourse();
          handleClose();
        })
        .catch(handleError);
    }
  };
  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  return (
    <>
      <Box
        // mb={2}
        sx={{
          height: "calc(100vh - 205px)",
          overflowY: "auto",
        }}
      >
        <CreateCourse {...renderNewCourseDetails()} />
      </Box>
      <MISFooterButton
        proceedButtonText={id ? T.UPDATE : T.SUBMIT}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={id ? handleUpdate : handleSubmit}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
    </>
  );
};

export default Form;
