import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { get } from "utils/lodash";
import { TableRow, Grid, TableCell, Typography, TableBody, styled, Rating, Tooltip, Badge, Box, IconButton } from "@mui/material";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { MainTitleTypography, SubTitleTypography } from "utils/members";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import memo from "utils/react";
import T from "T";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { MISCurrentUser } from "utils/validations";
import DeleteIcon from "@mui/icons-material/Delete";
import { capitalizeWords, lineSeparate } from "utils/commonFunction";
import { legends } from "../trainingModal";
import MISNoData from "components/common/MISNoData";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TitleTypography = styled(Typography)(({ rest }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "22px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: 200,
  paddingRight: rest?.paddingRight,
}));

const AssignTableBody = ({ allTableRows = [], handleReview = noop, handleDeleteCourse = noop, generate_Certificate = noop }) => {
  const { user } = MISCurrentUser();
  const currUserRole = get(user, "role", "");
  const currUserEmail = get(user, "user.userEmailId", "");
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { trackStatus, trainingCourse } = record;
          const assignmentID = get(record, "id", "");
          const user = get(record, "userId", []);
          const reassignCount = get(record, "reassignCount", 0);
          const assignedBy = get(record, "assignedBy.name", "");
          const courseName = get(record, "trainingCourse.courseName", "");
          const assignmentType = get(record, "trainingCourse.assignmentType", "");
          const subsCourses = get(record, "subsCourses", []);
          const courseDuration = get(record, "courseDuration", "");
          const practicalDuration = get(record, "practicalDuration", "");
          const trainingDuration = courseDuration + practicalDuration;
          const assignDate = get(record, "assignDate", "");
          const completionDate = get(record, "completionDate", "");
          const startDate = get(record, "startDate", "");
          const endDate = get(record, "endDate", "");
          const reviewDate = get(record, "reviewDate", "");
          const skillRate = get(record, "skillRate", "");
          const reviewer = get(record, "reviewer", "");
          const reviewerMailId = reviewer ? get(reviewer, "reviewer.userEmailId", "") : "";
          const ellapsedDate = Math.ceil((new Date(completionDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
          const courseType = get(trainingCourse, "assignmentType.courseAssignmentType", "");
          const courseTypeStatusCheck =
            courseType !== T.OFFLINE_TRAINING
              ? subsCourses.some((value) => value.status === T.REVIEW_PENDING || value.status === T.COMPLETED)
              : true;    // ![T.YET_TO_START].includes(trackStatus);  change to enable button on yet_to_start for offline
          const isLoggedInUserReviewer = reviewerMailId ? reviewerMailId === currUserEmail : true;
          const reviewerRoleCheck =
            isLoggedInUserReviewer ||
            ![T.PMO, T.PM, T.VP, T.HR].includes(currUserRole) ||
            ([T.PMO, T.PM, T.VP, T.HR].includes(currUserRole) && isLoggedInUserReviewer);
          const isReviewDisable = ![T.COMPLETED, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus)
            ? reviewerRoleCheck && courseTypeStatusCheck
            : true;
          const isCompleted =
            courseType !== T.OFFLINE_TRAINING
              ? subsCourses.some((value) => value.status === T.COMPLETED)
              : [T.COMPLETED, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus);
          const trainingAssignmentId = get(record, "id", "");
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell>
                <TitleTypography sx={{ color: NETSMARTZ_THEME_COLOR }}>{capitalizeWords(user.userName)}</TitleTypography>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.EMP_CODE}: </SubTitleTypography>
                  <TitleTypography ml={1}>{capitalizeWords(user.employeeCode)}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.DEPARTMENT}: </SubTitleTypography>
                  <TitleTypography ml={1}>
                    {user.userDepartment ? capitalizeWords(user.userDepartment.departmentName) : "-"}
                  </TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.EMAIL_ID}: </SubTitleTypography>
                  <TitleTypography ml={1}>{user.userEmailId}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.ATTEMPT_NO}: </SubTitleTypography>
                  <TitleTypography ml={1}>{reassignCount + 1}</TitleTypography>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>
                  <span style={{ wordWrap: "break-word" }}>{capitalizeWords(courseName)}</span>
                  <Tooltip title={trackStatus} placement="top">
                    <Badge
                      variant="dot"
                      sx={{
                        mx: 1,
                        "& .MuiBadge-badge": {
                          backgroundColor: legends[trackStatus]?.color || legends.default.color,
                        },
                      }}
                    />
                  </Tooltip>
                </TitleTypography>
                <TitleTypography>{assignmentType.courseAssignmentType}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                {subsCourses.length !== 0 && courseType !== T.OFFLINE_TRAINING
                  ? Children.toArray(
                      subsCourses.map((data, index) => {
                        const subsCourseName = get(data, "subCourse.subsCourseName", "");
                        const subCourseStatus = get(data, "status", "");

                        return (
                          <SubTitleTypography>
                            {capitalizeWords(subsCourseName)}
                            <Tooltip title={subCourseStatus} placement="top">
                              <Badge
                                variant="dot"
                                sx={{
                                  mx: 1,
                                  "& .MuiBadge-badge": {
                                    backgroundColor:
                                      subCourseStatus && legends[subCourseStatus] ? legends[subCourseStatus].color : "grey",
                                  },
                                }}
                              />
                            </Tooltip>
                          </SubTitleTypography>
                        );
                      }),
                    )
                  : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center">
                <TitleTypography>{trainingDuration ? `${trainingDuration} Hrs` : "-"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{assignedBy}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{assignDate}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{completionDate ? completionDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{startDate ? startDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{endDate ? endDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{reviewDate ? reviewDate : "--"}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TitleTypography>{ellapsedDate > 0 ? ellapsedDate : T.OVER}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                {![T.OFFLINE_TRAINING].includes(courseType) ? (
                  <Rating precision={0.5} defaultValue={0} value={Number(skillRate)} readOnly size="small" />
                ) : (
                  "N/A"
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Grid display={"inline-flex"}>
                  <IconButton
                    disabled={!isReviewDisable} // tempororily disable
                    onClick={() => handleReview(record)}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  >
                    <Tooltip title={T.REVIEW} placement="top">
                      {isCompleted ? <RateReviewIcon /> : <RateReviewOutlinedIcon />}
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteCourse(trainingAssignmentId, user.id)}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                    disabled={![T.YET_TO_START, T.COMPLETED, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus)}
                  >
                    <Tooltip title={T.REVOKE} placement="top">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => generate_Certificate(assignmentID)}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                    disabled={![T.COMPLETED, T.ATTENDED, T.PARTIALLY_ATTENDED, T.ABSENT].includes(trackStatus)}
                  >
                    <CardMembershipIcon />
                  </IconButton>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

AssignTableBody.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleAddEditProjectInfoDialog: PropTypes.func,
  generate_Certificate: PropTypes.func,
};

export default memo(AssignTableBody);
